import React from "react"
import ReservationInformation from './ReservationInformation'

export default function Complete( props ) {

  return (
    <>
      <div className="main-container">
        <div className="checkouts-wrapper order-complete">
          <h1 className='no-print'>
            { props.appProps.translations.reservations.thank_you_for_your_reservation }
          </h1>
          <h4 className='no-print'>
            { props.appProps.translations.reservations.confirmation_and_updates_email } { props.reservation.email }
          </h4>

          <ReservationInformation { ...props } />

        </div>
      </div>
    </>
  )
}
