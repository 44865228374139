import React, { useState, useEffect, useRef } from 'react'
import { FlashMessage, Loader } from '@reactiveonline/frontend_shared_components'
import PaymentMethod from './methods/PaymentMethod'
import TermsAndAgreements from './TermsAndAgreements'

const PaymentStep = (props) => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedMethod, setSelectedMethod] = useState({})
  const [acceptedTerms, setAcceptedTerms]   = useState(props.autoAcceptTermsAndAgreements ?? false)
  const [acceptedAgreements, setAcceptedAgreements] = useState(props.autoAcceptTermsAndAgreements ?? false)
  const [availableCards, setAvailableCards] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // STRIPE
  const [submitStripeCard, setSubmitStripeCard] = useState(false)
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState(null)
  const [stripePaymentIntentActionType, setStripePaymentIntentActionType] = useState(null)
  const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret] = useState(null)
  const [stripeSetupIntentActionType, setStripeSetupIntentActionType] = useState(null)
  // SIX
  const [showPaymentIframe, setShowPaymentIframe] = useState(false)
  const {
    uid, reservationPath, indirect, translations, appProps,
    stripePublishableKey, orderId, type, selectedAddons, setLoading
  } = props

  const TOKENIZED_PAYMENT_METHODS = ["CardlinkOnePayment", "AlphaBankPayment", "EurobankPayment"]

  const flashMessageRef = useRef(null)

  useEffect(() => {
    Rails.ajax({
      url: [appProps.paymentMethodsPath, `payment_type=${indirect ? 'indirect' : 'direct'}`].join('?'),
      type: 'get',
      success: (res) => {
        setPaymentMethods(res)
      }
    })

    return () => setPaymentMethods([])
  }, [])

  const handleSubmit = () => {
    if (props.canProceed) {
      const canProceed = props.canProceed()
      if (!canProceed) {
        return
      }
    }

    if (setLoading) {
      setLoading(true)
    }

    if (Object.keys(selectedMethod).length === 0) {
      appProps.flashMessage.show(appProps.translations.flash_messages.select_payment_method, 'error')
      return
    }

    if (!acceptedTerms || !acceptedAgreements) {
      appProps.flashMessage.show(appProps.translations.flash_messages.accept_terms_error, 'error')
      return
    }

    if (selectedMethod.type === "StripePayment") {
      setSubmitStripeCard(true)
    }
    else if (TOKENIZED_PAYMENT_METHODS.includes(selectedMethod.type) && !props.guestCheckout) {
      if (availableCards) {
        submitPaymentStep()
      } else {
        appProps.flashMessage.show(appProps.translations.flash_messages.no_cards_available, 'error')
        return
      }
    } else {
      submitPaymentStep()
    }
  }

  const setStripeError = () => {
    setSubmitStripeCard(false)
    setStripePaymentIntentActionType(null)
    setStripePaymentIntentClientSecret(null)
    setStripeSetupIntentActionType(null)
    setStripeSetupIntentClientSecret(null)
    setIsLoading(false)
  }

  const submitPaymentStep = (stripePaymentMethodId = null) => {
    setIsLoading(true)
    let fd = new FormData()

    if (props.paymentLink) {
      props.populateFormData(fd)
      fd.append('payment_link', true)
    }

    fd.append('reservation[payment_method_id]', selectedMethod.id)
    fd.append('reserve', true)
    fd.append('start_date', props.dateRange.start_date)
    fd.append('end_date', props.dateRange.end_date)
    if (props.chargeAtTimeOfBooking) {
      fd.append('charge_at_time_of_booking', true)
    }

    if (selectedAddons?.length > 0) {
      const addonData = selectedAddons.map(item => ({ id: item.id, quantity: item.quantity || '' }))
      fd.append('addons', JSON.stringify(addonData))
    }

    if (stripePaymentMethodId) {
      fd.append('stripe_payment_method_id', stripePaymentMethodId)
    }

    if(appProps.currentUserEmail) {
      fd.append("reservation[email]", appProps.currentUserEmail)
    }

    if (props.paymentId) {
      fd.append('payment_id', props.paymentId)
    }

    Rails.ajax({
      url: props.reservationPath,
      type: (props.paymentId || props.paymentLink) ? 'POST' : 'PATCH',
      contentType: 'application/json',
      dataType: 'json',
      data: fd,
      success: (response, statusText, xhr) => {
        if(response.redirect_url) {
          if (response.message) {
            flashMessageRef.current.show(response.message, 'success')
          }

          location.href = response.redirect_url
        } else if(response.redirect_form) {
          document.body.innerHTML += response.redirect_form
          const form = document.getElementById('payment-redirect-form')
          form.submit()

        } else if((response.requires_action || response.requires_confirmation) && (response.payment_intent_client_secret || response.setup_intent_client_secret)) {
          setSubmitStripeCard(false)
          if (response.payment_intent_client_secret) {
            setStripePaymentIntentActionType(response.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripePaymentIntentClientSecret(response.payment_intent_client_secret)
            setStripeSetupIntentActionType(null)
            setStripeSetupIntentClientSecret(null)

          } else {
            setStripePaymentIntentActionType(null)
            setStripePaymentIntentClientSecret(null)
            setStripeSetupIntentActionType(response.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripeSetupIntentClientSecret(response.setup_intent_client_secret)

          }

        } else if(response.redirect_iframe_url) {
          setShowPaymentIframe(true)
          document.getElementById('payment-iframe').src = response.redirect_iframe_url
        } else if (response.error_message) {
          appProps.flashMessage.show(response.error_message, 'error')
          window.location.reload()
        } else if (!response.available) {
          appProps.flashMessage.show(response.availableMessage, 'error')
          setIsLoading(false)
        } else if (!response.validDuration) {
          appProps.flashMessage.show(response.validDurationMessage, 'error')
          setIsLoading(false)
        } else if (!response.validRestrictions) {
          appProps.flashMessage.show(response.validRestrictionsMessage, 'error')
          setIsLoading(false)
        } else if (!response.validBookingSettings) {
          appProps.flashMessage.show(response.validBookingSettingsMessage, 'error')
          setIsLoading(false)
        }
      },
      error: (response, statusText, xhr) => {
        appProps.flashMessage.show(response.error, 'error')
        setSubmitStripeCard(false)
        setStripePaymentIntentClientSecret(null)
        setStripeSetupIntentClientSecret(null)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <div className="card checkout-step flex-box flex-column payment">
        <h3><b>{ props.appProps.translations.reservations.choose_payment }</b></h3>
        <div id="payment-methods-list" className="step-editor flex-box flex-wrap payment-methods">
          {
            paymentMethods.map((method, index) => {
              return(
                <PaymentMethod
                  key={ index }
                  appProps={ props.appProps }
                  paymentMethod={ method }
                  isSelected={ selectedMethod.id === method.id }
                  setSelectedMethod={ setSelectedMethod }
                  stripePublishableKey = { method.type === "StripePayment" ? stripePublishableKey : null }
                  submitStripeCard = { submitStripeCard }
                  stripePaymentIntentClientSecret = { stripePaymentIntentClientSecret }
                  stripePaymentIntentActionType={ stripePaymentIntentActionType }
                  stripeSetupIntentClientSecret = { stripeSetupIntentClientSecret }
                  stripeSetupIntentActionType={ stripeSetupIntentActionType }
                  setSubmitStripeCard = { setSubmitStripeCard }
                  setStripeError = { setStripeError }
                  orderId = { orderId }
                  type = { type }
                  completeOrder = { submitPaymentStep }
                  // paymentIframePath = { props.paymentIframePath }
                  setAvailableCards = { setAvailableCards }
                  setIsLoading = { setIsLoading }
                  setCurrentCardPath = { props.setCurrentCardPath }
                  cardsPath = { props.cardsPath }
                  addNewCardText = { props.addNewCardText }
                  guestCheckout = { props.guestCheckout }
                  billingDetails = { props.billingDetails }
                  payableAlreadyCompleted = { props.payableAlreadyCompleted }
                  // currency = { currency }
                />
              )
            })
          }

          { showPaymentIframe && paymentIFrame }
        </div>

        <TermsAndAgreements
          appProps={ props.appProps }
          legalTerms={ props.legalTerms }
          agreements={ props.agreements }
          acceptedAgreements={ acceptedAgreements }
          acceptedTerms={ acceptedTerms }
          setAcceptedAgreements={ setAcceptedAgreements }
          setAcceptedTerms={ setAcceptedTerms }
        />

        <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
          { isLoading ?
            <div className='button inverted flex-box items-center content-center'>
              <Loader size='medium'/>
            </div>
            :
            <div onClick={ handleSubmit } className='button'>
              { props.appProps.translations.reservations.proceed_payment }
            </div>
          }
        </div>
      </div>

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}

export default PaymentStep
