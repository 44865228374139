import React, { useState, Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

export default function Agreements({
  reservationAgreement, placeholders, agreementTranslation,
  placeholdersTranslations
}) {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className='main-container' style={{ display: 'block', width: '100%', marginTop: 30 }}>
      <Tabs selectedIndex={ tabIndex } onSelect={(index) => setTabIndex(index)}>
        <TabList>
          { reservationAgreement &&
            <Tab>{ agreementTranslation }</Tab>
          }
          { placeholders &&
            <Tab>{ placeholdersTranslations }</Tab>
          }
        </TabList>
        { reservationAgreement &&
          <TabPanel style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div dangerouslySetInnerHTML={{ __html: reservationAgreement }} className='tab-content'/>
          </TabPanel>
        }
        { placeholders &&
          <TabPanel style={{ position: 'relative', width: '100%', height: '100%' }}>
            { placeholders.map( (placeholder, index) => {
              return (
                <Fragment key={ index }>
                  { placeholder.value != '<p></p>' &&
                    <div
                      dangerouslySetInnerHTML={{ __html: placeholder.value }}
                      className='tab-content'
                    />
                  }
                </Fragment>
              )
            })}
          </TabPanel>
        }
      </Tabs>
    </div>
  )
}
