import React, { useState } from "react"
import { BankDetails, Chat } from '@reactiveonline/frontend_shared_components'
import Agreements from "./Agreements"
import ReservationPolicies from "./ReservationPolicies"
import ReservationCancel from "../account/shared/ReservationCancel"
import ContactDetails from "../rentals/rental_show/ContactDetails"
import LocationMap from "../rentals/rental_show/LocationMap"

export default function ReservationInformation( props ) {
  const mainProps = props.appProps

  function printOrder() {
    window.print();
    return false;
  }

  return (
    <>
      <div className='main-container'>

        <div className='flex-box flex-wrap'>
          <div className='rental card flex-box flex-column'>
            <div className='rental-image'>
              <img src={props.rentalImage} alt={ props.reservation.rental.name } />
            </div>
            <div className='rental-details'>
              <div className='rental-detail name'>
                <h3><b>{ props.reservation.rental.name }</b></h3>
              </div>

              { props.reservation.rental.translations &&
                <div className='rental-detail description' dangerouslySetInnerHTML={{ __html: props.reservation.rental.translations[0].description }} />
              }

              { props.reservation.rental.arrangements &&
                <div className='rental-detail arrangements'>
                  <div>
                    <b>{ mainProps.translations.rentals.sleep_arrangements }</b>
                  </div>
                  <div className="details-list">
                    { props.reservation.rental.arrangements.map( (item, index)=> {
                      return (
                        <div key={index} className='detail'>
                          { `${item.presentation}${index < props.reservation.rental.arrangements.length - 1 ? ', ' : ''}` }
                        </div>
                      )
                    })}
                  </div>
                </div>
              }

              { props.reservation.rental.rooms &&
                <div className='rental-detail rooms'>
                  <div>
                    <b>{ mainProps.translations.rentals.amenities }</b>
                  </div>
                  <div className="details-list">
                    { props.reservation.rental.rooms.map( (item, index)=> {
                      return (
                        <div key={index} className='detail'>
                          { `${item.presentation}${index < props.reservation.rental.rooms.length - 1 ? ', ' : ''}` }
                        </div>
                      )
                    })}
                  </div>
                </div>
              }

              { props.reservation.rental.amenities &&
                <div className='rental-detail amenities'>
                  <div>
                    <b>{ mainProps.translations.rentals.facilities }</b>
                  </div>
                  <div className="details-list">
                    { props.reservation.rental.amenities.map( (item, index)=> {
                      return (
                        <div key={index} className='detail'>
                          { `${item.presentation}${index < props.reservation.rental.amenities.length - 1 ? ', ' : ''}` }
                        </div>
                      )
                    })}
                  </div>
                </div>
              }

              { props.location &&
                <LocationMap
                  location={ props.location }
                />
              }

            </div>
          </div>

          <div className='order-details-wrapper'>
            <div className="order-details flex-box flex-column">
              <div className="order-detail-block card">
                <div className="order-detail-block-title">
                  { mainProps.translations.reservations.reservation_number }: { props.reservation.number }
                </div>
                <div className="order-detail-block-description">
                  { mainProps.translations.reservation.from }: { props.from } <br/>
                  { mainProps.translations.reservation.to }: { props.to }
                </div>

                { props.checkInTime && props.checkOutTime &&
                  <div className="order-detail-block-description" style={{ marginTop: 20 }}>
                    <div>
                      <b>{ mainProps.translations.reservation.check_in }:</b> {` ${props.checkInTime != 'flexible' ? props.checkInTime + ':00' : mainProps.translations.rental.flexible}` }
                    </div>
                    <div>
                      <b>{ mainProps.translations.reservation.check_out }:</b> {` ${props.checkOutTime != 'flexible' ? props.checkOutTime + ':00' : mainProps.translations.rental.flexible}` }
                    </div>
                  </div>
                }
              </div>

              <div className="order-detail-block card">
                <div className="order-detail-block-title flex-box items-center content-space-between">
                  <div>
                    { mainProps.translations.reservations.payment_status }:
                  </div>
                  <div>
                    { props.reservation.payment_state ? mainProps.translations.reservation[props.reservation.payment_state] : '-' }
                  </div>
                </div>
                <div className="order-detail-block-description">
                  { props.reservation.cost.line_items.map((item, index) => (
                    <div
                      key={ index }
                      className='flex-box items-center content-space-between'
                    >
                      <div>{ item.description }</div>
                      <div>{ item.net }</div>
                    </div>
                  ))}

                  <div className='flex-box items-center content-space-between'>
                    <div>{ mainProps.translations.reservation_summary.vat }</div>
                    <div>{ props.reservation.cost.vat_total }</div>
                  </div>

                  <div className='flex-box items-center content-space-between' style={{ marginTop: 20, marginBottom: 20 }}>
                    <div>{ mainProps.translations.reservations.reservation_total }</div>
                    <div>{ props.reservationTotal }</div>
                  </div>

                  { props.reservation.payment_state !== 'paid' && props.reservation.booking_status !== 'inquiry' &&
                    <>
                      <div className='flex-box items-center content-space-between' style={{ marginTop: 20 }}>
                        <div>{ mainProps.translations.reservations.paid_total }</div>
                        <div className='completed-payment'>{ props.paidAmount }</div>
                      </div>

                      <div className='flex-box items-center content-space-between'>
                        <div>{ mainProps.translations.reservations.remaining_total }</div>
                        <div>{ props.remainingAmount }</div>
                      </div>

                      { props.reservationPolicyPaymentDetails.length > 0 &&
                        <>
                          <div style={{ marginTop: 15 }}>
                            <div className='payment-schedule-title'>
                              { props.appProps.translations.reservations.payment_schedule }
                            </div>

                            { props.reservationPolicyPaymentDetails.map((paymentDetail, index) => (
                              <div
                                key={ index }
                                className='flex-box items-center content-space-between'
                              >
                                <div>{ paymentDetail.date }</div>
                                <div className={ `${ paymentDetail.completed ? 'completed-payment' : '' }`}>{ paymentDetail.display_amount }</div>
                              </div>
                            ))}
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>

              <div className="order-detail-block card">
                <div className="order-detail-block-title">
                  { mainProps.translations.reservations.billing_info }
                </div>
                <div className="order-detail-block-description">
                  { props.billingAddress }
                </div>

                { props.reservation.booking_status === 'booked' &&
                  <>
                    <div className="order-detail-block-description flex-box items-center content-space-between" style={{ margin: '20px 0px' }}>
                      <div>
                        { mainProps.translations.reservation_summary.payment_method }:
                      </div>
                      <div>
                        { props.paymentMethod?.title }
                      </div>
                    </div>
                    { props.bankDetails && props.bankDetails.length > 0 &&
                      <BankDetails
                        appProps={ props.appProps }
                        bankDetails={ props.bankDetails }
                      />
                    }
                  </>
                }
              </div>

              { props.reservation.notes &&
                <div className="order-detail-block card">
                  <div className="order-detail-block-title">
                    { mainProps.translations.reservations.reservation_notes }
                  </div>
                  <div className="order-detail-block-description">
                    { props.reservation.notes }
                  </div>
                </div>
              }

              <ReservationPolicies { ...props } />

            </div>
          </div>
        </div>
      </div>

      <div className='main-container no-print'>
        <Chat
          appProps={ mainProps }
          createChatMessagePath={ props.createChatMessagePath }
          fetchChatMessagesPath={ props.fetchChatMessagesPath }
          chattableId={ props.reservation.id }
          chattableApiUid={ props.reservation.api_uid }
          chattableType={ 'reservation' }
          chatTitle={ mainProps.translations.reservations.contact_host }
        />
      </div>

      <Agreements
        reservationAgreement={ props.agreement }
        placeholders={ props.placeholders }
        agreementTranslation={ mainProps.translations.reservation.agreements }
        placeholdersTranslations={ mainProps.translations.reservation.placeholders }
      />

      <div className='main-container no-print'>
        <div className="flex-box content-space-between">
          <div className="flex-box flex-column items-start flex-1 card">
            <h3><b>{ mainProps.translations.reservations.print_reservation }</b></h3>
            <div>{ mainProps.translations.reservation.print_reservation_text }</div>
            <div className='button inverted pointer' style={{ width: 'auto', marginTop: 20 }} onClick={ ()=> printOrder() }>
              { mainProps.translations.reservations.print_reservation }
            </div>
          </div>
          <div className="flex-1 card">
            <ReservationCancel
              appProps={ mainProps }
              reservationStatus={ props.reservation.booking_status }
              reservationStatusTranslation={ props.reservationStatus }
              cancelText={ mainProps.translations.actions.cancel }
              confirmText={ "OK" }
              confirmationDialog={ mainProps.translations.reservations.cancel_reservation_confirm }
              cancelReservationPath={ props.cancelReservationPath }
            />
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="reservation-contacts">
          <ContactDetails
            appProps={ props.appProps }
            contact={ props.reservation.rental.contact }
          />
        </div>
      </div>
    </>
  )
}
