import React from "react"

export default function ReservationPolicies( props ) {
  const mainProps = props.appProps

  return (
    <>
      { props.reservationStatus &&
        <>
          { props.policyEnabled &&
            <div className="card order-detail-block">
              <div className="order-detail-block-title">
                { mainProps.translations.reservation.payment_policies }
              </div>
              <div className="order-detail-block-description flex-box flex-column">
                <div>{ props.reservation.policy_info.policy_type }</div>
                { props.reservation.policy_info.refund_rules.map((rule, index) => (
                  <div key={ index }>
                    { rule.description }
                  </div>
                ))}
              </div>
            </div>
          }

          { props.reservation.policy_info.warranty &&
            <div className="card order-detail-block">
              <div className="order-detail-block-title">
                { mainProps.translations.reservation.warranty_title }
              </div>
              <div className="order-detail-block-description">
                { !!props.reservation.policy_info.warranty?.description
                  ? <div className='flex-box items-center content-space-between'>
                      <div>{ props.reservation.policy_info.warranty.description }</div>
                      <div>{ props.reservation.cost.warranty }</div>
                    </div>
                  : <>
                      <div>
                        <div>
                          { props.reservation.policy_info.warranty.charge_description }
                        </div>
                        <div>
                          <div style={{ marginTop: '20px' }}>
                            { props.appProps.translations.reservations.damage_protection_host_reason }
                          </div>
                          <div style={{ marginTop: '20px' }}>
                            { props.reservation.policy_info.warranty.charge_reason }
                          </div>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
          }
        </>
      }
    </>
  )
}
