import React from 'react'
import PaymentStep from './PaymentStep'
import { AuthorizableModal } from '@reactiveonline/frontend_shared_components'
import Summary from './Summary'

export default function PolicyPaymentLink(props) {
  const {
    appProps, reservation, payPolicyByLinkPath, userAddress, payment,
    agreements, legalTerms
  } = props
  const { translations } = appProps

  return (
    <>
      <div className='main-container'>
        { !appProps.user && reservation.rental.policy ?
          <AuthorizableModal
            appProps={ appProps }
            authorizableScreen={ 'login' }
          />
          :
          <div className='checkouts-wrapper'>
            <Summary
              appProps={ appProps }
              currentRental={ reservation.rental }
              cost={ reservation.cost }
            />

            <div className='checkout-container'>
              <div className='card'>
                <div className='card-content'>
                  <div className='card-title'>
                    <h3>
                      { `${ translations.reservations.payment_transaction_fail }: ${ reservation.number }` }
                    </h3>
                  </div>

                  <div className='card-body'>
                    <div>
                      { `${ translations.reservations.payment_failure_reason }: ${ payment.error_message }` }
                    </div>

                    { payment.customFields.find(el => el.key === 'damage_protection_charge_reason') &&
                      <div style={{ margin: '20px 0' }}>
                        <div>
                          { translations.reservations.damage_protection_host_reason }
                        </div>
                        <div>
                          <b>
                            { `${ payment.customFields.find(el => el.key === 'damage_protection_charge_reason').value }` }
                          </b>
                        </div>
                      </div>
                    }

                    <div>
                      { `${ translations.reservations.payment_process_help_text } ${ payment.display_amount }` }
                    </div>
                  </div>
                </div>
              </div>

              <PaymentStep
                appProps={ appProps }
                autoAcceptTermsAndAgreements={ true }
                indirect
                dateRange={ reservation.date_range }
                stripePublishableKey = { props.stripePublishableKey }
                orderId = { props.orderId }
                type = { props.type }
                cards = { props.cards }
                setCurrentCardPath = { props.setCurrentCardPath }
                cardsPath = { props.cardsPath }
                addNewCardText = { props.addNewCardText }
                guestCheckout = { !appProps.currentUserEmail }
                paymentId = { props.payment.id }
                reservationPath={ props.payPolicyByLinkPath }
                payableAlreadyCompleted={ true }
                billingDetails = { {
                  address: {
                    city: userAddress.city,
                    country: userAddress.country_alpha2_code,
                    line1: userAddress.street,
                    postal_code: userAddress.postal_code,
                    state: userAddress.state_name
                  },
                  email: reservation.email,
                  name: userAddress.full_name,
                  phone: userAddress.mobile_phone || userAddress.phone
                } }
                agreements={ agreements }
                legalTerms={ legalTerms }
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}
