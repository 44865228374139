import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types';
import DateSelectStep from './DateSelectStep'
import RentalSelectStep from './RentalSelectStep'
import DetailsStep from './DetailsStep'
import PaymentStep from './PaymentStep'
import InquiryStep from './InquiryStep'
import AddonsStep from './AddonsStep';
import Summary from './Summary'

import { toURLEncoded } from 'helpers/utils'
import { FlashMessage } from '@reactiveonline/frontend_shared_components';

export default class Reservation extends Component {
  constructor(props){
    super(props)

    const { billing_address, email, date_range, guests, notes, rental, uid, cost } = this.props.reservation

    this.state = {
      availableAddons: this.props.availableAddons ?? [],
      selectedAddons: [],
      billingAddress: this.props.userAddress ? this.props.userAddress : billing_address,
      currentStep: date_range ? 2 : 1,
      dateRange: date_range,
      email: email,
      guests: guests,
      isLoading: false,
      notes: notes,
      rental: rental,
      uid: uid,
      cost: cost,
      countries: []
    }

    this.flashMessageRef = React.createRef()
    this.onNextStep = this.onNextStep.bind(this)
    this.setState = this.setState.bind(this)
    this.setIsLoading = this.setIsLoading.bind(this)
    this.setCountries = this.setCountries.bind(this)
    this.setBillingAddress = this.setBillingAddress.bind(this)
    this.setNotes = this.setNotes.bind(this)
  }

  setCountries(countries) {
    this.setState({ countries: countries })
  }

  setIsLoading(loading) {
    this.setState({ isLoading: loading })
  }

  setBillingAddress(state) {
    this.setState({ billingAddress: { ...this.state.billingAddress, ...state } })
  }

  setNotes(state) {
    this.setState({ notes: state })
  }

  onUpdateReservation(attributes, stepOnSuccess = null) {
    this.setState({ isLoading: true })

    $.ajax({
      url: this.props.reservationPath,
      type: 'PATCH',
      headers : {
        'X-CSRF-Token': Rails.csrfToken()
      },
      dataType: 'json',
      data: { reservation: attributes },
      // data: { reservation: attributes, addons: JSON.stringify(this.state.selectedAddons) },
      success: (res) => {
        const { email, date_range, guests, notes, rental, cost, redirectTo } = res

        if(redirectTo){
          window.location.href = redirectTo
        }

        this.setState( prevState => {
          return ({
            email: email,
            dateRange: date_range,
            guests: rental ? guests : 1,
            notes: notes,
            rental: rental,
            cost: cost,
            currentStep: stepOnSuccess || prevState.currentStep,
            isLoading: false
          })
        })
      },
      error: res => {
        this.flashMessageRef.current.show(res.responseJSON.error, 'error')
        this.setState(prevState => ({ ...prevState, isLoading: false }))
      }
    })
  }

  onUpdateAddons(addons) {
    const fd = new FormData()

    const addonData = addons.map(item => ({ id: item.id, quantity: item.quantity || '' }))
    fd.append('reservation[uid]', this.state.uid)
    fd.append('addons', JSON.stringify(addonData))

    this.setState({ isLoading: true })

    Rails.ajax({
      url: this.props.reservationPath,
      type: 'PATCH',
      dataType: 'json',
      data: fd,
      success: (res) => {
        this.setState({
          cost: res.cost,
          isLoading: false
        })

        this.setState({ selectedAddons: addons })
      }
    })
  }

  onResetToStep(step) {
    if (step < this.state.availableAddons.length ? 4 : 3) {
      const attributes = {
        email: '',
        notes: '',
        billing_address_attributes: { id: this.state.billingAddress.id, _destroy: true }
      }

      if (this.state.selectedAddons.length > 0) {
        this.onUpdateReservation({ uid: this.state.uid })
      }
    }

    let selectedAddons = this.state.selectedAddons
    if (step < 3) {
      selectedAddons = []
    }

    this.setState({ currentStep: step, selectedAddons: selectedAddons })
  }

  onNextStep() {
    this.setState({isLoading: true})

    Rails.ajax({
      type: 'GET',
      url: this.props.reservationPath,
      dataType: 'json',
      success: res => {
        const { billing_address, email, date_range, guests, notes, rental, cost } = res

        this.setState(prevState => {
          return({
            billingAddress: (billing_address && billing_address.id) ? billing_address : (this.props.userAddress || {}),
            email: email,
            dateRange: date_range,
            guests: guests,
            notes: notes,
            rental: rental,
            cost: cost,
            currentStep: prevState.currentStep + 1
          })
        })
        this.setState({isLoading: false})
      }
    })
  }

  getRentals(successCallback) {
    this.setState({isLoading: true})

    Rails.ajax({
      url: this.props.availableRentalsPath,
      type: 'GET',
      dataType: 'json',
      data: toURLEncoded({
        date_range_id: this.state.dateRange.id
      }),
      success: res => {
        if(successCallback) successCallback(res)
      },
      complete: () => this.setState({isLoading: false})
    })
  }

  setGuests(guestNumber) {
    this.setState({ guests: guestNumber })
  }

  setAvailableAddons(addons) {
    this.setState({ availableAddons: addons })
  }

  render(){
    const isBookable = this.props.reservation.rental.bookability === 'instant'
    const { billingAddress, currentStep, dateRange, email, guests, isLoading, notes, rental, showPaymentIframe, uid, selectedPaymentMethod, cost } = this.state
    const { appProps, reservationDateRangePath, disabledCheckout, agreements, legalTerms, availabilityInfo, bookingSettings, minimumDays } = this.props

    return(
      <>
        <div className='main-container'>
          { this.props.disabledCheckout ?
            <div className="main-container">
              <div className="not-found-wrapper flex-box flex-column items-center content-center">
                <h2>
                  { appProps.translations.general.checkout_closed }
                </h2>
              </div>
            </div>
            :
            <div className='checkouts-wrapper'>
              <Summary
                appProps={ this.props.appProps }
                currentStep={ currentStep }
                currentRental={ rental }
                cost={ cost }
                isLoading={ isLoading }
              />

              <div className="checkout-container">
                <div className="heading">
                  <h1>
                    { this.props.appProps.translations.reservations.reservation_title }
                  </h1>
                </div>
                <div className="checkout-steps">
                  <DateSelectStep
                    appProps={ this.props.appProps }
                    currentStep = { currentStep }
                    dateRange = { dateRange }
                    isLoading = { isLoading }
                    setAvailableAddons={ this.setAvailableAddons.bind(this) }
                    setIsLoading={ this.setIsLoading }
                    rental = { rental }
                    onNextStep = { this.onNextStep }
                    reservationDateRangePath={ this.props.reservationDateRangePath }
                    onSetCurrent = { () => this.onResetToStep(1) }
                    availabilityInfo={ availabilityInfo }
                    bookingSettings={ bookingSettings }
                    minimumDays={ minimumDays }
                  />

                  <RentalSelectStep
                    appProps={ this.props.appProps }
                    currentStep = { currentStep }
                    getRentals = { this.getRentals.bind(this) }
                    isLoading = { isLoading }
                    setIsLoading={ this.setIsLoading }
                    onSetCurrent = { () => this.onResetToStep(2) }
                    rental = { rental }
                    onUpdateReservation = { this.onUpdateReservation.bind(this) }
                    guests={ guests }
                    setGuests={ this.setGuests.bind(this) }
                  />

                  { this.state.availableAddons.length > 0 &&
                    <AddonsStep
                      appProps={ this.props.appProps }
                      availableAddons={ this.state.availableAddons }
                      currentStep={ currentStep }
                      isLoading={ isLoading }
                      onNextStep={ this.onNextStep }
                      onSetCurrent={ () => this.onResetToStep(3) }
                      onUpdateAddons={ this.onUpdateAddons.bind(this) }
                      selectedAddons={ this.state.selectedAddons }
                    />
                  }

                  <DetailsStep
                    appProps={ this.props.appProps }
                    billingAddress = { billingAddress || {} }
                    setBillingAddress={ this.setBillingAddress }
                    currentStep = { currentStep }
                    email = { email }
                    currentUserEmail= { this.props.appProps.currentUserEmail }
                    isLoading = { isLoading }
                    setIsLoading={ this.setIsLoading }
                    notes = { notes }
                    setNotes={ this.setNotes }
                    onNextStep = { this.onNextStep }
                    onSetCurrent = { () => this.onResetToStep(this.state.availableAddons.length ? 4 : 3) }
                    addonStepExists = { this.state.availableAddons.length > 0 }
                    selectedAddons= { this.state.selectedAddons }
                    countries={ this.state.countries }
                    setCountries={ this.setCountries }
                  />

                  { currentStep === (this.state.availableAddons.length ? 5 : 4) &&
                    // currentStep === 4 &&
                    <>
                      { isBookable ?
                        <PaymentStep
                          appProps={ this.props.appProps }
                          showPaymentIframe = { showPaymentIframe }
                          indirect = { rental.has_policy }
                          dateRange={ dateRange }
                          stripePublishableKey = { this.props.stripePublishableKey }
                          orderId = { this.props.orderId }
                          type = { this.props.type }
                          paymentIframePath = { this.props.paymentIframePath }
                          cards = { this.props.cards }
                          setCurrentCardPath = { this.props.setCurrentCardPath }
                          cardsPath = { this.props.cardsPath }
                          addNewCardText = { this.props.addNewCardText }
                          guestCheckout = { !appProps.currentUserEmail }
                          reservationPath={ this.props.reservationPath }
                          selectedAddons = { this.state.selectedAddons }
                          chargeAtTimeOfBooking = { this.props.reservation.rental.charge_at_time_of_booking }
                          billingDetails = { {
                            address: {
                              city: this.state.billingAddress.city,
                              country: this.state.countries.find(country => country.value === this.state.billingAddress.country_numeric_code)?.alpha2Code,
                              line1: this.state.billingAddress.street,
                              postal_code: this.state.billingAddress.postal_code,
                              state: this.state.countries.find(country => country.value === this.state.billingAddress.country_numeric_code)?.states?.find(state => state.value === this.state.billingAddress.state_region_code)?.label
                            },
                            email: this.state.email,
                            name: this.state.billingAddress.full_name,
                            phone: this.state.billingAddress.mobile_phone || this.state.billingAddress.phone
                          } }
                          agreements={ this.props.agreements }
                          legalTerms={ this.props.legalTerms }
                        />
                      :
                        <InquiryStep
                          appProps={ this.props.appProps }
                          dateRange={ dateRange }
                          reservationPath={ this.props.reservationPath }
                          agreements={ this.props.agreements }
                          legalTerms={ this.props.legalTerms }
                        />
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        <FlashMessage ref={ this.flashMessageRef } />
      </>
    )
  }
}

Reservation.propTypes = {
  availableRentalsPath: PropTypes.string,
  reservation: PropTypes.object,
  getCountriesPath: PropTypes.string,
  reservationPath: PropTypes.string,
  reservationDateRangePath: PropTypes.string,
}

Reservation.defaultProps = {
  paymentMethods: []
}
