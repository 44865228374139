import React, { useRef, useState, useEffect } from "react"
import { FlashMessage, SliderImages } from '@reactiveonline/frontend_shared_components'
import PropTypes from 'prop-types';
import WeekdayPrices from './rental_show/WeekdayPrices'
import InformationBox from './rental_show/InformationBox'
import CheckAndReserve from './widget/CheckAndReserve'
import ContactDetails from './rental_show/ContactDetails'
import LocationMap from "./rental_show/LocationMap";
import { NO_IMAGE_SOURCE } from './constants'

const sectionLabel = (labelString) => <h2>{labelString}</h2>
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function Rental(props) {
  const { appProps, published, amenities, arrangements, base_rate, description, images, information,
          location, name, rooms, slug, checkOrReservePath, thumbnailsPosition,
          rentalPageLayout, uid, contact, policy, availabilityInfo, bookingSettings, minimumDays } = props

  const [sliderImages, setSliderImages] = useState(initSliderImages())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [guests, setGuests] = useState(null)
  const [checkAndReserveRenderKey, setCheckAndReserveRenderKey] = useState(1)
  const rentalDescription = useRef(null)
  const scrollToDescription = () => scrollToRef(rentalDescription)
  const rentalAmenities = useRef(null)
  const scrollToAmenities = () => scrollToRef(rentalAmenities)
  const rentalAdditional = useRef(null)
  const scrollToAdditional = () => scrollToRef(rentalAdditional)
  const rentalPricing = useRef(null)
  const scrollToPricing = () => scrollToRef(rentalPricing)
  const flashMessageRef = useRef(null)

  function initSliderImages() {
    return (
      images.length > 0 ? images.map( image => ( {original: image.preview, thumbnail: image.thumbnail, fullscreen: image.banner} )) : [{
        original: NO_IMAGE_SOURCE,
        thumbnail: '',
        fullscreen: ''
      }]
    )
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (Object.keys(params).length > 0) {
      if (params.unavailable) {
        if (params.reason === 'dates') {
          flashMessageRef.current.show(appProps.translations.flash_messages.dates_not_available, 'error')
        } else if (params.reason === 'duration') {
          flashMessageRef.current.show(appProps.translations.flash_messages.invalid_duration, 'error')
        } else if (params.reason === 'restrictions') {
          flashMessageRef.current.show(appProps.translations.flash_messages.invalid_checkin_checkout, 'error')
        } else if (!!params.reason) {
          flashMessageRef.current.show(params.reason, 'error')
        }

        setStartDate(params.start_date)
        setEndDate(params.end_date)
        setGuests(+params.guests)
        setCheckAndReserveRenderKey(Math.random())
        window.history.replaceState({}, '', window.location.origin + window.location.pathname)
      }
    }
  }, [])

  return (
    <>
      <div className={ `${rentalPageLayout } rental-show`  }>
        { published ?
          <>
            <div className='hero-banner'
                  style={{
                    backgroundImage: `url(${ images.length > 0 ? images[0].banner : NO_IMAGE_SOURCE })`
                  }}>
              <h1 className='hero-banner-text'>
                {name}
              </h1>
              { rentalPageLayout == 'version_four' &&
                <div className='rental-tabs-wrapper'>
                  <div className='main-container'>
                    <div className='rental-tabs '>
                      { description &&
                        <button className='rental-tab' onClick={ scrollToDescription }>
                          { appProps.translations.rentals.description }
                        </button>
                      }
                      { amenities.length > 0 &&
                        <button className='rental-tab' onClick={ scrollToAmenities }>
                          { appProps.translations.rentals.amenities }
                        </button>
                      }
                      { base_rate.notes &&
                        <button className='rental-tab' onClick={ scrollToAdditional }>
                          { appProps.translations.rentals.additional_information }
                        </button>
                      }
                      { base_rate.prices &&
                        <button className='rental-tab' onClick={ scrollToPricing }>
                          { appProps.translations.rentals.pricing_plan }
                        </button>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className={`rental-show-container ${rentalPageLayout != 'version_one' ? 'main-container' : ''}`}>
              <div className='rental-panel'>
                <div className='rental-details-wrapper'>
                  { rentalPageLayout != 'version_one' &&
                    <div className={`rental-image ${thumbnailsPosition}`} style={{ width: '100%' }}>
                      <SliderImages
                        images={ sliderImages }
                        thumbnailPosition={ thumbnailsPosition }
                        showThumbnails={ true }
                        showFullscreenButton={ false }
                        showNav={ true }
                        showBullets={ true }
                        lightbox={ true }
                      />
                    </div>
                  }
                  <InformationBox
                    arrangements={arrangements}
                    information={information}
                    rooms={rooms}
                  />
                  <div className='rental-details'>
                    { description &&
                      <div className='rental-detail'>
                        <div className='rental-detail-section' ref={ rentalDescription }>
                          { sectionLabel(appProps.translations.rentals.description) }
                          <div className='text-area' dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        { rentalPageLayout == 'version_one' &&
                          <div className='image-area'>
                            <img src={ images.length > 0 && images[1] ? images[1].banner : NO_IMAGE_SOURCE } />
                          </div>
                        }
                      </div>
                    }
                    { amenities.length > 0 &&
                      <div className='rental-detail'>
                        { rentalPageLayout == 'version_one' &&
                          <div className='image-area'>
                            <img src={ images.length > 0 && images[2] ? images[2].banner : NO_IMAGE_SOURCE } />
                          </div>
                        }
                        <div className='rental-detail-section' ref={ rentalAmenities }>
                          { sectionLabel(appProps.translations.rentals.amenities) }
                          <div className='amenities-list'>
                            {
                              amenities.map((amenity, i) => {
                                return(<div key={i} className='amenities-list-item'> {amenity.presentation} </div>)
                              })
                            }
                          </div>
                        </div>
                      </div>
                    }
                    { base_rate.notes &&
                      <div  className='rental-detail'>
                        <div className='rental-detail-section' ref={ rentalAdditional }>
                          { sectionLabel(appProps.translations.rentals.additional_information) }
                          <div className='text-area' dangerouslySetInnerHTML={{ __html: base_rate.notes }} />
                        </div>
                        { rentalPageLayout == 'version_one' &&
                          <div className='image-area'>
                            <img src={ images.length > 0 && images[3] ? images[3].banner : NO_IMAGE_SOURCE } />
                          </div>
                        }
                      </div>
                    }
                    { base_rate.prices &&
                      <div  className='rental-detail'>
                        <div className={`rental-detail-section weekdays ${rentalPageLayout != 'version_two' && 'main-container'}`} ref={ rentalPricing }>
                          { sectionLabel(appProps.translations.rentals.pricing_plan) }
                          <WeekdayPrices
                            appProps={ appProps }
                            prices={ base_rate.prices }
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className='rental-sidebar'>
                  <CheckAndReserve
                    key={ checkAndReserveRenderKey }
                    appProps={ appProps }
                    pricePerNight={ base_rate.price_per_night }
                    checkOrReservePath={ checkOrReservePath }
                    rentalId={ uid }
                    availabilityInfo={ availabilityInfo }
                    bookingSettings={ bookingSettings }
                    minimumDays={ minimumDays }
                    initialStartDate={ startDate }
                    initialEndDate={ endDate }
                    initialGuests={ guests }
                  />
                  { rentalPageLayout != 'version_one' && contact.name &&
                    <ContactDetails
                      appProps={ appProps }
                      contact={ contact }
                    />
                  }

                </div>
              </div>
            </div>

            { rentalPageLayout == 'version_one' && contact.name &&
              <div className='rental-show-container'>
                <ContactDetails
                  appProps={ appProps }
                  contact={ contact }
                />
              </div>
            }

            <div className='map-wrapper'>
              {
                location && location.longitude && location.latitude &&
                  <LocationMap location={location}/>
              }
            </div>
          </>
          :
          <>
            <div className="not-found-wrapper flex-box flex-column items-center content-center">
              <h1>
                { appProps.translations.general.rental_not_found_title }
              </h1>
              <h5>
                { appProps.translations.general.rental_not_found_description }
              </h5>
            </div>
          </>
        }
      </div>

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}

Rental.propTypes = {
  arrangements: PropTypes.array,
  base_rate: PropTypes.object,
  id: PropTypes.number,
  images: PropTypes.array,
  information: PropTypes.object,
  location: PropTypes.object,
  name: PropTypes.string,
  rooms: PropTypes.array,
  type: PropTypes.string
}
