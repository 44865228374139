import React, { useState } from 'react'
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'

export default function ReservationCancel(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const cancelReservation = () => {
    const form = document.getElementById(`cancel-reservation`)
    form.submit()
    setLoading(true)
    closeModal()
  }

  let content = null
  if (props.reservationStatus === "cancelled") {
    content = <div style={{ padding: "8px 20px", backgroundColor: "#cd3838", color: "#ffffff", width: 'auto', marginTop: 20 }}>
                { props.reservationStatusTranslation }
              </div>
  }
  else if (["booked", "inquiry"].includes(props.reservationStatus)) {
    content = <div className="button btn" onClick={ openModal } style={{ width: 'auto', marginTop: 20 }}>
                { props.cancelText }
              </div>
  } else {
    content = <div className="red-text">
                { props.reservationStatusTranslation }
              </div>
  }

  return (
    <div className="actions flex-box flex-column">
      <div>
        <h3><b>{ props.appProps.translations.reservation.cancel_reservation }</b></h3>
      </div>
      <div className='flex-box flex-column items-start'>
        <div>
          { props.appProps.translations.reservation.cancel_reservation_text }
        </div>
        { content }
      </div>

      { ["booked", "inquiry"].includes(props.reservationStatus) &&
        <form id={`cancel-reservation`}
              action={ props.cancelReservationPath }
              method="post">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
        </form>
      }

      { modalIsOpen &&
        <Modal
          visible
          mode='small'
          acceptAction={ cancelReservation }
          closeModal={ closeModal }
          abortAction={ closeModal }
          abortText={ props.appProps.translations.general.back }
          saveText={ props.appProps.translations.general.confirm }
          styles={ { minHeight: '250px' } }
        >
          <h6> { props.confirmationDialog } </h6>
        </Modal>
      }

      { loading && <Loader position='center' fullPage /> }
    </div>
  )
}
