import React, { useState, useEffect } from 'react'
import  { IncompleteStep } from './Steps'
import { SelectInput, Loader } from '@reactiveonline/frontend_shared_components'
import ErrorMessage from '../shared/ErrorMessage'
import { validatePhoneNumber } from '@reactiveonline/frontend_shared_components/utils'

export default function DetailsStep({
  appProps, currentStep, billingAddress, setBillingAddress, email, isLoading,
  setIsLoading, addonStepExists, selectedAddons, countries, setCountries,
  onSetCurrent, onNextStep, notes, setNotes
}) {
  const [currentCountry, setCurrentCountry] = useState(null)
  const [currentState, setCurrentState] = useState(null)

  useEffect( () => {
    if (billingAddress) {
      Rails.ajax({
        url: appProps.getCountriesPath,
        type: 'GET',
        success: (res) => {
          const formattedCountries = res.available_countries
          const countrySelected = formattedCountries.filter(c => c.value == billingAddress.country_numeric_code)[0] || null
          setCountries(formattedCountries)
          setCurrentCountry(countrySelected)

          if (billingAddress.state_region_code && countrySelected.states_required) {
            const stateSelected = countrySelected.states.filter(c => c.value == billingAddress.state_region_code)[0] || null
            setCurrentState(stateSelected)
          }
        }
      })
    }
  }, [billingAddress])

  if (currentStep < (addonStepExists ? 4 : 3)) {
    return(<IncompleteStep stepTitle={ appProps.translations.reservations.reservation_details }/>)
  }

  if (currentStep > (addonStepExists ? 4 : 3)) {
    return (
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ onSetCurrent }>
        <div className='edit_icon'></div>
        <div className='rental-details'>
          <div className='details'>
            <div className='detail'>
              <b>
                { `${ appProps.translations.reservations.full_name }: ${ billingAddress.full_name }` }
              </b>
            </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.email }: ${ email }` }
              </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.phone }: ${ billingAddress.phone || billingAddress.mobile_phone }` }
            </div>
          </div>
        </div>
      </div>
    )
  }

  function clearErrors() {
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  function setCountry(countryEntry) {
    setCurrentCountry(countryEntry)
    setCurrentState(null)
  }

  function handleChangePhone(event) {
    const value = event.target.value

    if (value.match(/^(\+?\d{0,20})?$/)) {
      setBillingAddress({ phone: value })
    }
  }

  function handleChangeMobile(event) {
    const value = event.target.value

    if (value.match(/^(\+?\d{0,20})?$/)) {
      setBillingAddress({ mobile_phone: value })
    }
  }

  function handleProceed() {
    clearErrors()

    let noErrors = true
    const {
      city, full_name, postal_code, state_region_code, street, phone,
      mobile_phone
    } = billingAddress

    if (!full_name || full_name.length === 0) {
      ErrorMessage('billing-full-name', 'red', 'billing-full-name-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if ((!phone && !mobile_phone) || (phone && !validatePhoneNumber(phone))) {
      ErrorMessage('billing-phone', 'red', 'billing-phone-error', appProps.translations.flash_messages.phone_invalid, 'red')
      noErrors = false
    }

    if ((!phone && !mobile_phone) || (mobile_phone && !validatePhoneNumber(mobile_phone))) {
      ErrorMessage('billing-mobile-phone', 'red', 'billing-mobile-phone-error', appProps.translations.flash_messages.phone_invalid, 'red')
      noErrors = false
    }

    if (!street || street.length === 0) {
      ErrorMessage('billing-street', 'red', 'billing-street-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!city || city.length === 0) {
      ErrorMessage('billing-city', 'red', 'billing-city-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!postal_code || postal_code.length === 0) {
      ErrorMessage('billing-postal-code', 'red', 'billing-posta-code-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!currentCountry) {
      ErrorMessage('billing-country', 'red', 'billing-country-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (currentCountry && currentCountry.states_required && !currentState) {
      ErrorMessage('billing-state', 'red', 'billing-state-error', appProps.translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (noErrors) {
      setIsLoading(true)

      let formdata = new FormData()
      formdata.append('reservation[billing_address_attributes][city]', city)
      formdata.append('reservation[billing_address_attributes][full_name]', full_name)
      formdata.append('reservation[billing_address_attributes][postal_code]', postal_code)
      formdata.append('reservation[billing_address_attributes][street]', street)
      formdata.append('reservation[billing_address_attributes][phone]', phone)
      formdata.append('reservation[billing_address_attributes][mobile_phone]', mobile_phone)
      formdata.append('reservation[billing_address_attributes][country_numeric_code]', currentCountry.value)
      formdata.append('reservation[billing_address_attributes][state_region_code]', currentState ? currentState.value : null)

      if (addonStepExists && selectedAddons?.length > 0) {
        const addonData = selectedAddons.map(item => ({ id: item.id, quantity: item.quantity || '' }))

        formdata.append('addons', JSON.stringify(addonData))
      }

      if (appProps.currentUserEmail) {
        formdata.append("reservation[email]", appProps.currentUserEmail)
      }

      Rails.ajax({
        url: appProps.reservationPath,
        type: 'PATCH',
        contentType: 'application/json',
        dataType: 'json',
        data: formdata,
        success: (response, statusText, xhr) => {
          onNextStep()
        }
      })
    }
  }

  return (
    <div className="card checkout-step flex-box flex-column">
      <h3><b>{ appProps.translations.reservations.contact_information_title }</b></h3>
      <div className='step-editor flex-box flex-wrap'>
        <div className='form'>
          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.email }
              </label>
              <div style={{ marginLeft: 5 }}>*</div>
            </div>
              <input
                disabled
                type='email'
                value={ appProps.currentUserEmail || billingAddress.email }
              />
          </div>

          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.full_name }
              </label>
              <div style={{ marginLeft: 5 }}>*</div>
            </div>

            <input
              type='text'
              id='billing-full-name'
              value={ billingAddress.full_name || '' }
              onChange={ event => setBillingAddress({ full_name: event.target.value }) }
            />
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.phone }
                </label>

                { (!billingAddress.phone || !billingAddress.mobile_phone) &&
                  <div style={{ marginLeft: 5 }}>*</div>
                }
              </div>

              <input
                type='tel'
                id='billing-phone'
                value={ billingAddress.phone || '' }
                onChange={ handleChangePhone }
              />
            </div>

            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.mobile }
                </label>

                { (!billingAddress.phone || !billingAddress.mobile_phone) &&
                  <div style={{ marginLeft: 5 }}>*</div>
                }
              </div>

              <input
                type='tel'
                id='billing-mobile-phone'
                value={ billingAddress.mobile_phone || '' }
                onChange={ handleChangeMobile }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.address }
                </label>
                <div style={{ marginLeft: 5 }}>*</div>
              </div>
              <input
                type='text'
                id='billing-street'
                value={ billingAddress.street || '' }
                onChange={ event => setBillingAddress({ street: event.target.value }) }
              />
            </div>

            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.city }
                </label>
                <div style={{ marginLeft: 5 }}>*</div>
              </div>

              <input
                type='text'
                id='billing-city'
                value={ billingAddress.city || '' }
                onChange={ event => setBillingAddress({ city: event.target.value }) }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.postal_code }
                </label>
                <div style={{ marginLeft: 5 }}>*</div>
              </div>
              <input
                type='text'
                id='billing-postal-code'
                value={ billingAddress.postal_code || '' }
                onChange={ event => setBillingAddress({ postal_code: event.target.value }) }
              />
            </div>

            <div className="field">
              <SelectInput
                inputId='billing-country'
                wrapperClasses='checkout-step'
                label={ `${ appProps.translations.reservations.country } *` }
                options={ countries.map(country => ({ ...country, title: country.label })) }
                currentOption={ currentCountry ? currentCountry.value : null }
                setCurrentOption={ setCountry }
              />
            </div>

            { currentCountry && currentCountry.states_required &&
              <div className="field">
                <SelectInput
                  inputId='billing-state'
                  wrapperClasses='checkout-step'
                  label={ `${ appProps.translations.reservations.state } *` }
                  options={ currentCountry.states }
                  currentOption={ currentState ? currentState.value : null }
                  setCurrentOption={ setCurrentState }
                />
              </div>
            }

            <div className="field notes-wrapper">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.reservation_notes }
                </label>
              </div>
              <textarea
                value={ notes || '' }
                onChange={ event => setNotes(event.target.value) }
              />
            </div>

          </div>

          <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
            { isLoading ?
              <div className='button inverted flex-box items-center content-center'>
                <Loader size='medium'/>
              </div>
            :
              <button
                type='button'
                className='button'
                onClick={ handleProceed }
              >
                { appProps.translations.reservations.next_step }
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
